.enterprise-result {
  margin: 30px !important;
  border-radius: 30px;

  .heading-container {
    padding: 20px;
    text-align: center;

    .heading-title {
      color: white;
      font-size: 24px;
    }

    .ertragswert {
      font-size: 3rem;
      color: #1abc9c;
    }
  }

  .result-column {
    background-color: white;
    border-radius: 30px;
    padding: 20px;
    flex: 1 1 80%;

    .result-form {
      margin-top: 20px;
      text-align: center;

      .form-field {
        .form-title {
          font-size: 20px;
          color: #666;
        }

        .form-description {
          font-size: 16px;
          color: #999;
        }
      }

      .form-input {
        margin: 10px 0;
      }

      .form-button {
        margin-top: 10px;
        background-color: #4183c4;
        color: #fff;
      }
    }
  }
}